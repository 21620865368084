<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";

import BarChartUserSouscrire from "./BarChartUserSouscrire";
import Multiselect from "vue-multiselect";
import VueJsonToCsv from "vue-json-to-csv";
import VueHtml2pdf from "vue-html2pdf";
import router from "@/router/index.js";
export default {
  page: {
    title: "Vue NSIA",
    meta: [{ name: "description", content: appConfig.description }],
    data: {
      type: Array,
      required: true,
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    BarChartUserSouscrire,
    VueJsonToCsv,
    VueHtml2pdf
  },
  data() {
    return {
      title: "Vue NSIA",
      tmpCoverFile: null,
      file: null,
      fileModif: null,
      showDetail: false,
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "NSIA Assurance",
          active: true,
        },
      ],
      moyensTransport: ["MOTO", "VOITURE", "TAXI", "BUS"],
      typesDistance: ["COURT", "MOYEN", "LONG"],
      experience: ["R-Débutant", "R-Qualifié", "R-Ambassadeur"],
      codePromoForm: {
        titre: "",
        description: "",
        pathImage: "",
      },
      codePromoFormModif: {
        titre: "",
        description: "",
        pathImage: "",
      },
      codePromoRowInfo: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      codePromo: [],
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "client", sortable: true, label: "Nom et Prénom(s)" },
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "option", sortable: true, label: "Option" },
        { key: "nombrePlace", sortable: true, label: "Nombre de place" },
     
        { key: "montant", sortable: true, label: "Montant" },
        { key: "date_assurance", sortable: true, label: "Date départ" },
        { key: "immatriculation", sortable: true, label: "Immatriculation Auto/Moto" },
  
   
 
      ],
      abonnements: [],
      labels: [
        {
          nom: { title: "Nom et Prénom" },
          trajet: { title: "Trajets" },
          options: { title: "Options" },
          montant: { title: "Montant" },
          date: { title: "Date" },
        },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      showactive: false,
      erreur: false,
      yearSelected: { name: "2022", value: 2022 },
      yearvalue: 2022,
      defaultMonth: null,
      defaultChoice: { id: null, value: "" },
      years: [
        2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032,
        2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044,
        2045, 2046, 2047, 2048, 2049, 2050,
      ],
      yearvalueU: 2022,
      nombre: 0,
      totalRecette: 0,
      today: new Date(),
      month: [
        { id: "01", value: "Janvier" },
        { id: "02", value: "Février" },
        { id: "03", value: "Mars" },
        { id: "04", value: "Avril" },
        { id: "05", value: "Mai" },
        { id: "06", value: "Juin" },
        { id: "07", value: "Juillet" },
        { id: "08", value: "Août" },
        { id: "09", value: "Septembre" },
        { id: "10", value: "Octobre" },
        { id: "11", value: "Décembre" },
        { id: "12", value: "Décembre" },
      ],
      currentTime: "",
      idFacture: "",
      numUser: "",
      numberUserCode: "",
      emailUser: "",
      departTrajet: "",
      pointRencontreDepart: "",
      arriveeTrajet: "",
      pointArriveTrajet: "",
      description: "",
      quantite: 0,
      prixunitaireTrajet: 0,
      totalTrajet: 0,
      descriptionAssurance: "",
      quantiteAssurance: 0,
      PrixUnitaireAssurance: 0,
      totalAssurance: 0,
      totalHT: 0,
      tva: 0.18,
      totalTTC: 0,
      nameUser: "",
      userAdresse: "",
      depart: "",
      arrive: "",
      cout: 0,
      dateReserv: "",
      couTAssurance: 0,

      ttc: 0,
      niveauUser:JSON.parse(localStorage.getItem("user")).niveau,

      abonnementPaye:[]
    };
  },
  validations: {},

  mounted() {
    this.init();
    // ffc400
    this.yearvalueU = this.today.getFullYear();
    this.yearvalue = this.today.getFullYear();
    this.defaultMonth = this.month[this.today.getMonth()];

    console.log(this.month[this.today.getMonth()]);
    this.loadData();
  },
  methods: {
    async init() {
      const listAssurance = await apiRequest(
        "GET",
        "assurance/list",
        undefined,
        false
      );

      if (listAssurance && listAssurance.data) {
        this.totalRows = listAssurance.data.totalAssurance;
        const assuranceListe = listAssurance.data.map((assurance) => {
          console.log("les assurances ",assurance);
          return {
            id: assurance.id,
            client: `${assurance.client_id.firstname} ${assurance.client_id.lastname}`,
            trajet:
              assurance.trajet_id.lieuDepart.split(",")[0] +
              " >> " +
              assurance.trajet_id.lieuArrivee.split(",")[0],
            option: assurance.option ?assurance.option :"null",
            date_assurance: new Date(assurance.date_assurance).toLocaleString(
              "fr-FR"
            ),
            montant: assurance.montant,
            nombrePlace: assurance.nbrPlaceReserve,
            idTrajet: assurance.trajet_id,
            idClient: assurance.client_id,
            immatriculation: assurance.immatriculation,
            
          };
       
        });
        this.abonnements = assuranceListe;
       /*  this.abonnements.forEach(element=>{
        this.verifyPayement(element)
        } ) */
             console.log(this.abonnementPaye)
      }
    },


    async loadData() {
      this.totalRecette = 0;
      const gains = await apiRequest(
        "GET",
        "assurance/start",
        undefined,
        false
      );
     // console.log('this.totalRecette', this.totalRecette);

      if (gains && gains.data) {
        this.totalRows = gains.data.totalAssurance;
        console.log(this.totalRows);
        let dataRecette = gains.data.annees;
        dataRecette.forEach((element) => {
          if (this.yearvalue == element.annee) {
            this.totalRecette += element.total;
          }
        });
        //this.totalRecette = gains.data.totalRecette;
      }
    },



    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleSuccess(msg) {
      console.log("good ", msg);
    },
    handleError(err) {
      console.log("error ", err);
    },
    editRow(row) {
      this.showEdit = true;
      this.codePromoFormModif.titre = row.titre;
      this.codePromoFormModif.description = row.description;
      this.codePromoFormModif.id = row.id;
      this.codePromoFormModif.visible = row.visible;
    },
    desactiverRow(row) {
      this.showdisable = true;
      this.codePromoRowInfo = row;
    },
    activerpromo(row) {
      this.showactive = true;
      this.codePromoRowInfo = row;
    },
    deleteRow(row) {
      this.showDelete = true;
      this.codePromoRowInfo = row;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    handleFileUploadModif() {
      this.fileModif = this.$refs.files.files[0];
    },

    async submitcodePromoForm() {
      if (
        this.codePromoForm.reduction != 0 &&
        this.codePromoForm.dateLimite != "" &&
        this.codePromoForm.moyensTransport.length > 0 &&
        this.codePromoForm.typesDistance.length > 0 &&
        this.codePromoForm.experience.length > 0
      ) {
        var moyensT = "";
        var typesD = "";
        var exp = "";

        this.codePromoForm.moyensTransport.forEach((element) => {
          moyensT += element + ",";
        });

        this.codePromoForm.typesDistance.forEach((element) => {
          typesD += element + ",";
        });

        this.codePromoForm.experience.forEach((element) => {
          exp += element + ",";
        });

        const returncodePromo = await apiRequest(
          "POST",
          "codepromo",
          {
            code: this.codePromoForm.code,
            reduction: this.codePromoForm.reduction,
            dateLimite: this.codePromoForm.dateLimite,
            birthday: false,
            moyensTransport: moyensT,
            typesDistance: typesD,
            experience: exp,
          },
          false
        );

        if (returncodePromo && returncodePromo.data) {
          this.init();
        }
      } else {
        console.log(
          "new Date(this.codePromoForm.dateLimite):",
          new Date(this.codePromoForm.dateLimite)
        );
        this.erreur = true;
      }
      /**/
    },
    async submitcodePromoFormModif() {
      let formData = new FormData();
      formData.append("id", this.codePromoFormModif.id);
      formData.append("titre", this.codePromoFormModif.titre);
      formData.append("sousTitre", this.codePromoFormModif.soustitre);
      formData.append("codePromoLink", this.codePromoFormModif.url_codePromo);
      formData.append("file", this.files);
      formData.append("visible", this.codePromoFormModif.visible);

      const returncodePromo = await apiRequest(
        "PUT",
        "codePromo",
        formData,
        true
      );

      if (returncodePromo && returncodePromo.data) {
        this.init();
      }
    },
    async desactivepromo() {
      this.showdisable = false;
      const returncodePromo = await apiRequest(
        "POST",
        "codePromo-off",
        { id: this.codePromoRowInfo.id, visible: false },
        false
      );

      if (returncodePromo && returncodePromo.data) {
        this.init();
      }
    },
    async activepromo() {
      this.showactive = false;
      const returncodePromo = await apiRequest(
        "POST",
        "codePromo-off",
        { id: this.codePromoRowInfo.id, visible: true },
        false
      );

      if (returncodePromo && returncodePromo.data) {
        this.init();
      }
    },
    async deletepromo() {
      this.showDelete = false;
      const returncodePromo = await apiRequest(
        "POST",
        "codepromo/action",
        { id: this.codePromoRowInfo.id, visible: false },
        false
      );

      if (returncodePromo && returncodePromo.data) {
        this.init();
      }
    },
    scanFacture(item) {
      this.nameUser = item.client;
      this.showDetail = true;
      this.idFacture = `${item.id}-${item.idClient.id}-${item.idTrajet.id}`;
      this.userAdresse = item.idClient.adresse;
      this.numUser = item.idClient.tel;
      this.numberUserCode = item.idClient.indicatifContactTel;

      (this.depart = item.idTrajet.lieuDepart),
        (this.arrive = item.idTrajet.lieuArrivee),
        (this.cout = item.idTrajet.prix),
        (this.dateReserv = new Date(item.date_assurance).toLocaleString(
              "fr-FR"
            ));
            this.couTAssurance=item.montant

            this.generatePDF();
    },

    goToDetail(row) {
      if(!(this.niveauUser[0]==14 && this.niveauUser.length==1) ){
      router.push(`/souscription/detail-assurance/${row.id}`);
        
      }
      console.log("Salut chef");
    },

    goHello(){
console.log("Salut chef");
    },

generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
 

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },


    
    async verifyPayement(objectTocheck) {
      if(objectTocheck.idTrajet.statutTrajet=="ACTIF"){

      

      const verify = await apiRequest(
        "POST",
        "get-facture",
        { idClient: objectTocheck.idClient.id, idTrajet: objectTocheck.idTrajet.id },
        false
      );

      if (verify && verify.data) { 
     if(verify.data && verify.data.estPayer && verify.data.statut=="ACTIF"){
      this.abonnementPaye.push(objectTocheck)
      console.log("list des abonnements payéz ", this.abonnementPaye);
     }
      }
    }
    },

  },

  computed: {
    filterAssuranceByMonth: function () {
      return this.abonnements.filter(
        (abonnement) =>
          !abonnement.date_assurance
            .split(" ")[0]
            .split("/")[1]
            .indexOf(this.defaultChoice.id)
      );
    },
  },

  watch: {
    yearvalue: {
      handler(val) {
        console.log("l'année sélectionnée est :", val);
        this.yearvalue;
        this.loadData();
      },
    },

    defaultMonth: {
      handler(val) {
        console.log(Number(val.id));

        if (val.id != "" && val.id) {
          this.defaultChoice.id = val.id;
          this.defaultChoice.value = val.value;
        }
      },
    },

    /*  
     let  date =Number(item.date_assurance.split(" ")[0].split('/')[1])
         console.log(date);
            return date===(Number(val.id)); 
   
        }


      }
      */
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="p-1">
          <div class="row mb-4 d-flex justify-content-center">
            <div class="mx-auto d-flex justify-content-between">
              <div class="">
                <multiselect
                  style="text-align: center"
                  v-model="yearvalue"
                  :options="years"
                  :close-on-select="true"
                  :show-labels="false"
                  :allow-empty="false"
                ></multiselect>
              </div>
              &nbsp;&nbsp;
              <div class="mt-2">Total : {{ totalRecette }}</div>
            </div>
          </div>
          <!-- Bar Chart -->
          <b-card>
            <BarChartUserSouscrire :height="300" :annee="yearvalue" />
          </b-card>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active"
                      >Historique des souscriptions</a
                    >
                  </template>
                  <div
                    class="row d-flex justify-content-between align-items-center mt-4"
                  >
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="exportFlex">
                      <div class="mb-2">
                        <multiselect
                          style="text-align: start"
                          v-model="defaultMonth"
                          :options="month"
                          track-by="value"
                          label="value"
                          :close-on-select="true"
                          :show-labels="false"
                          :allow-empty="false"
                        ></multiselect>
                      </div>
                      <div>
                        <vue-json-to-csv
                          :json-data="abonnements"
                          :csv-title="'assurance'"
                          :separator="';'"
                          @success="(val) => handleSuccess(val)"
                          @error="(val) => handleError(val)"
                        >
                          <button type="button" class="teal button__custom">
                            <b>Exporter en csv</b>
                          </button>
                        </vue-json-to-csv>
                      </div>
                    </div>

                    <div class="inputCol p-3">
                      <div class="input">
                        <input
                          type="search"
                          id="serchInput"
                          placeholder="Rechercher..."
                          v-model="filter"
                        />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      style="cursor: pointer"
                      :fields="fields"
                      :items="filterAssuranceByMonth"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      @row-clicked="goToDetail"
                    >
                <!--  <template v-slot:cell(action)="row">
                        <div class="text-start">
                          <a
                            href="javascript:void(0);"
                            class="text-success text-center"
                            @click=" goToDetail(row.item)"
                            v-b-tooltip.hover
                            title="inprimer facture"
                          >
                            <i class="mdi mdi-fax font-size-22"></i>
                          </a>
                        </div>
                      </template>   -->
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            :rows="abonnements"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="erreur"
      title="Veuillez remplir toutes les options."
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="info" class="ml-3 mr-4" @click="erreur = false"
          >Ok</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment supprimer ce code promo ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deletepromo"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showactive"
      title="Voulez-vous vraiment activer bon promo ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showactive = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="activepromo"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Editer utilisateur"
      title-class="font-18"
      hide-footer
    >
      <form
        enctype="multipart/form-data"
        @submit.prevent="submitcodePromoFormModif"
      >
        <div class="form-group">
          <label for="tite">Titre</label>
          <input
            type="text"
            id="title"
            class="form-control"
            placeholder="titre"
            v-model="codePromoFormModif.titre"
          />
        </div>
        <div class="form-group">
          <label for="tite">Sous titre</label>
        </div>
        <div class="form-group">
          <label for="tite">texte</label>
          <input
            type="text"
            id="title"
            class="form-control"
            placeholder="sous titre"
            v-model="codePromoFormModif.soustitre"
          />
        </div>
        <div class="form-group">
          <label for="tite">Lien du bon promo</label>
          <input
            type="url"
            id="title"
            class="form-control"
            placeholder="lien du bon promo"
            v-model="codePromoFormModif.url_codePromo"
          />
        </div>
        <div class="form-group">
          <label for="title">Image</label>
          <input
            type="file"
            id="files"
            ref="files"
            v-on:change="handleFileUploadModif"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <button class="btn btn-primary float-right">Modifier</button>
      
        </div>
        <div class="form-group">
          <label for="tite">image</label>
          <input type="file" id="title" class="form-control" />
        </div>
        <div class="form-group">
          <button class="btn btn-primary float-right">Ajouter</button>
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modalInvoice"
      centered
      size="xl"
      v-model="showDetail"
      title="Imprimer facture"
      title-class="font-18"
      hide-footer
    >

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="nightprogrammerpdf"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-margin="10"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
    
      </section>
    </vue-html2pdf>





      <table
      id="tablePrint"
        data-module="table 02"
        border="0"
        width="100%"
        cellpadding="0"
        cellspacing="0"
        style="padding: 0; background: #ffffff"
        class="currentTable"
      >
        <tr valign="top">
          <td class="editable">
            <table
              width="800px"
              style="
                padding: 30px;
                margin: 0 auto 0 auto;
                width:750px;
                background: #ffffff;
                border-right: 1px solid #ddd;
                border-left: 1px solid #ddd;
              "
              cellpadding="0"
              cellspacing="0"
              bgcolor="#fff"
            >
              <tr valign="top">
                <td>
                  <h1
                    style="
                      font-family: Ruda, Arial, Helvetica, sans-serif;
                      font-weight: 300;
                      font-size: 20px;
                      text-transform: capitalize;
                    "
                    align="center"
                    data-color="Headline01"
                    data-size="Headline01"
                    data-min="20"
                    data-max="26"
                  >
                    Salut {{ nameUser }} !
                  </h1>
                  <div
                    style="
                      height: 1px;
                      background: #37b24d;
                      width: 100px;
                      margin: 0 auto 0 auto;
                    "
                  ></div>
                </td>
              </tr>
              <tr valign="top">
                <td style="font-size: 0; line-height: 0" height="20">&nbsp;</td>
              </tr>
              <tr valign="top">
                <td style="padding: 0; width: 100%; border: 1px solid #ddd">
                  <!-- ///////////////////////////////// table payment ///////////////////////////////// -->

                  <table
                    border="0"
                    width="100%"
                    cellpadding="0"
                    cellspacing="0"
                    style="padding: 0; margin: 0"
                  >
                    <tr>
                      <td
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: bold;
                          font-size: 12px;
                          border-top: 1px solid #fff;
                          border-left: 1px solid #fff;
                          padding: 8px 5px;
                          background: #37b24d;
                          color: #fff;
                        "
                        align="center"
                      >
                        Facture
                      </td>
                      <td
                        colspan="8"
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 12px;
                          border-top: 1px solid #fff;
                          border-right: 1px solid #fff;
                          padding: 8px 5px;
                          background: #37b24d;
                          color: #fff;
                        "
                        width="100%"
                        align="left"
                      >
                        {{ idFacture }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6">
                        <table
                          border="0"
                          width="100%"
                          cellpadding="0"
                          cellspacing="0"
                          style="padding: 0; margin: 0"
                        >
                          <tr valign="top">
                            <td style="border-right: 1px solid #ddd">
                              <div
                                style="
                                  font-family: Open Sans, Arial, Helvetica,
                                    sans-serif;
                                  font-weight: 300;
                                  font-size: 13px;
                                  line-height: 1.4em;
                                  border-bottom: 1px solid #ddd;
                                  padding: 8px 5px;
                                "
                                width="100%"
                                align="left"
                              >
                                DE
                              </div>
                              <div
                                style="
                                  font-family: Ruda, Arial, Helvetica,
                                    sans-serif;
                                  font-weight: 300;
                                  font-size: 11px;
                                  line-height: 1.4em;
                                  border-top: 1px solid #fff;
                                  border-left: 1px solid #fff;
                                  padding: 8px 5px;
                                  background: #fff;
                                  color: #333;
                                "
                                width="100%"
                                align="left"
                              >
                                RAYNIS RMOBILITY<br />imm pharmacie Zogbo, Zogbo
                                Cotonou<br />Téléphone: (+229) 51390971
                              </div>
                            </td>
                            <td>
                              <div
                                style="
                                  font-family: Open Sans, Arial, Helvetica,
                                    sans-serif;
                                  font-weight: 300;
                                  font-size: 13px;
                                  line-height: 1.4em;
                                  border-bottom: 1px solid #ddd;
                                  padding: 8px 5px;
                                "
                                width="100%"
                                align="right"
                              >
                                À
                              </div>
                              <div
                                style="
                                  font-family: Ruda, Arial, Helvetica,
                                    sans-serif;
                                  font-weight: 300;
                                  font-size: 11px;
                                  line-height: 1.4em;
                                  border-top: 1px solid #fff;
                                  border-left: 1px solid #fff;
                                  padding: 8px 5px;
                                  background: #fff;
                                  color: #333;
                                "
                                width="100%"
                                align="right"
                              >
                                {{ nameUser }}<br />{{ userAdresse
                                }}<br />Téléphone: ({{ numberUserCode }})
                                {{ numUser }}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 12px;
                          background-color: #ccc;
                          color: #fff;
                          line-height: 22px;
                          border-top: 1px solid #fff;
                          border-left: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 5px;
                          width: 15%;
                        "
                        align="center"
                      >
                        Intitulé
                      </td>
                      <td
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 12px;
                          background-color: #ccc;
                          color: #fff;
                          line-height: 22px;
                          border-top: 1px solid #fff;
                          border-left: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 5px;
                          width: 15%;
                        "
                        align="center"
                      >
                        Date
                      </td>
                      <td
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 12px;
                          background-color: #ccc;
                          color: #fff;
                          line-height: 22px;
                          border-top: 1px solid #fff;
                          border-left: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 5px;
                          width: 25%;
                        "
                        align="center"
                      >
                        Départ
                      </td>
                      <td
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 12px;
                          background-color: #ccc;
                          color: #fff;
                          line-height: 22px;
                          border-top: 1px solid #fff;
                          border-left: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 5px;
                          width: 25%;
                        "
                        align="left"
                      >
                        Arrivé
                      </td>
                      <td
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 12px;
                          background-color: #ccc;
                          color: #fff;
                          line-height: 22px;
                          border-top: 1px solid #fff;
                          border-left: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 5px;
                          width: 30%;
                        "
                        align="right"
                      >
                        Coût en FCFA
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 13px;
                          line-height: 1.4em;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                        "
                        align="center"
                      >
                        Réservation de trajet
                      </td>
                      <td
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 13px;
                          line-height: 1.4em;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                        "
                        align="center"
                      >
                        {{ dateReserv }}
                      </td>
                      <td
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 13px;
                          line-height: 1.4em;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                        "
                        align="left"
                      >
                        {{ depart }}
                      </td>
                      <td
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 13px;
                          line-height: 1.4em;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                        "
                        align="right"
                      >
                        {{ arrive }}
                      </td>
                      <td
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 13px;
                          line-height: 1.4em;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                        "
                        align="center"
                      >
                        {{ cout }}
                      </td>
                    </tr>
                    <tr>
                    <td
                        colspan="3"
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-left: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                      ></td>
                      <td
                        colspan="1"
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        Coût assurance 
                      </td>
                      <td
                        colspan="1"
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        {{couTAssurance}} FCFA
                      </td>
                  </tr>
                  <tr>
                    <td
                        colspan="3"
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-left: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                      ></td>
                      <td
                        colspan="1"
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        Total HT
                      </td>
                      <td
                        colspan="1"
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        {{cout+couTAssurance}} FCFA
                      </td>
                  </tr>
                  <tr>
                    <td
                        colspan="3"
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-left: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                      ></td>
                      <td
                        colspan="1"
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        TVA 18%
                      </td>
                      <td
                        colspan="1"
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        {{(cout+couTAssurance)*0.18}} FCFA
                      </td>
                  </tr>
                  <tr>
                    <td
                        colspan="3"
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-left: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                      ></td>
                      <td
                        colspan="1"
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        Total TTC 
                      </td>
                      <td
                        colspan="1"
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        {{cout+couTAssurance +((cout+couTAssurance)*0.18)}} FCFA
                      </td>
                  </tr>
                   <!--  <tr>
                      <td
                        colspan="3"
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-left: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                      ></td>
                      <td
                        colspan="2"
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        Total
                      </td>
                      <td
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-right: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        $840
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="3"
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-left: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                      ></td>
                      <td
                        colspan="2"
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        Disc (10%)
                      </td>
                      <td
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-right: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        $84
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="3"
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-left: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                      ></td>
                      <td
                        colspan="2"
                        style="
                          font-family: Open sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        Shipping
                      </td>
                      <td
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border-top: 1px solid #fff;
                          border-right: 1px solid #fff;
                          border-bottom: 1px solid #ddd;
                          padding: 8px 5px;
                          background-color: #f8f8f8;
                        "
                        align="right"
                      >
                        $25
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="3"
                        style="
                          font-family: Ruda, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border: 1px solid #fff;
                          padding: 10px 5px;
                        "
                      ></td>
                      <td
                        colspan="2"
                        style="
                          font-family: Open sans, Arial, Helvetica, sans-serif;
                          font-weight: 300;
                          font-size: 15px;
                          line-height: 1.4em;
                          border: 1px solid #fff;
                          padding: 10px 5px;
                        "
                        align="right"
                      >
                        Grand Total
                      </td>
                      <td
                        style="
                          font-family: Open Sans, Arial, Helvetica, sans-serif;
                          font-weight: 600;
                          font-size: 15px;
                          line-height: 1.4em;
                          letter-spacing: 1px;
                          border: 1px solid #fff;
                          background-color: #37b24d;
                          color: #fff;
                          padding: 10px 5px;
                        "
                        align="right"
                      >
                        $781
                      </td>
                    </tr> -->
                  </table>
                  <!-- ///////////////////////////////// table payment end ///////////////////////////////// -->
                </td>
              </tr>
              <tr valign="top">
                <td style="font-size: 0; line-height: 0" height="20">&nbsp;</td>
              </tr>
              <tr valign="top">
                <td>
                  <div
                    data-color="ContentTable02"
                    data-size="ContentTable02"
                    style="
                      font-family: Open Sans, Arial, Helvetica, sans-serif;
                      font-size: 14px;
                      font-weight: 300;
                      line-height: 1.4em;
                    "
                    align="left"
                  >
                   Nous vous remercions de la confiance que vous nous faite.
                  </div>
                </td>
              </tr>
              <tr valign="top">
                <td style="font-size: 0; line-height: 0" height="20">&nbsp;</td>
              </tr>
              <tr valign="top">
                <td>
                  <div
                    data-color="Button01"
                    data-size="Button01"
                    style="
                      font-family: Ruda, Arial, Helvetica, sans-serif;
                      font-weight: 300;
                      font-size: 14px;
                      line-height: 14px;
                      padding: 15px;
                    "
                    align="center"
                  >
                    <button
                      style="
                        background: #37b24d;
                        border-radius: 2px;
                        text-transform: capitalize;
                        padding: 10px 20px;
                        color: #fff;
                        font-family: Ruda, Arial, Helvetica, sans-serif;
                        font-size: 18px;
                        line-height: 1.4em;
                        text-decoration: none;
                        cursor:pointer;
                        border:none;
                      
                      "

                      id="print"
                      class="editable"
                 
                      >Imprimer</button
                    >
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
     
    </b-modal>
  </Layout>
</template>
<style scoped>
.imageCard {
  display: flex;
  align-items: center;
  flex-direction: column;
}

thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

#chart {
  position: relative;
  margin-top: 40px;
  width: 500px;
  height: 200px;
}
@-webkit-keyframes bargrowth {
  0% {
    max-height: 0%;
  }
  100% {
    max-height: 100%;
  }
}
@keyframes bargrowth {
  0% {
    max-height: 0%;
  }
  100% {
    max-height: 100%;
  }
}
.bar {
  -webkit-animation: bargrowth 1000ms ease;
  animation: bargrowth 1000ms ease;
  position: absolute;
  bottom: 0;
  display: inline-block;
  background: #89b800;
  box-shadow: 1px -1px #79a300, 2px -2px #79a300, 3px -3px #79a300,
    4px -4px #79a300, 5px -5px #79a300, 6px -6px #79a300, 7px -7px #79a300,
    8px -8px #79a300, 9px -9px #79a300, 10px -10px #79a300;
}

.wrapper {
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
  width: 500px;
  font-family: Helvetica;
  font-size: 12px;
}
.wrapper > h1,
.wrapper > p {
  margin: 0;
}

.button__custom {
  color: var(--gray-0);
  background-color: #37b24d;
  height: 2.3rem;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  margin-bottom: 15px;
}

.exportFlex {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 400px;
}

.multiselect__tags {
  border: 3px solid #37b24d !important;
  color: #37b24d !important;
}
.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}



@media screen {
  #print {
    display: none;
   }
}

@media print {
 body * {
  visibility:hidden;
  }
  #print, #print * {
    visibility:visible;
  }
  #print {
    position:absolute;
    left:0;
    top:0;
  }
}



.np-btn {
  padding: 2px 8px;
  margin: 12px 8px;
  border: 1px solid #da1010;
  width: 110px;
  background: #da1010;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
}
</style>
